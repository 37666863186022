import styled from 'styled-components';

export const Section = styled.section`
    display: ${({ grid }) => grid && 'grid' };
    padding: ${({ noPadding }) => noPadding ? '0' : 'var(--sectionPadding)'};
    margin: ${({ withMargin }) => withMargin && '92px 0'};
    background: ${({ darker }) => darker ? 'var(--darkSectionBg)' : 'var(--lightSectionBg)'};
    overflow: hidden; 
    position: ${({ relative }) => relative && 'relative'};

    @media (max-width: 768px) {
        &.what_is_jamstack {
            padding: 3rem 1rem 6rem;
        }
    }
    
    // @media (max-width: 980px) {
    //     padding: ${({ noPadding }) => noPadding ? '0' : '1rem'}; 
    // }
    //
    // @media (max-width: 768px) {
    //     padding: ${({ noPadding }) => noPadding ? '0' : '1rem'}; 
    // }
`;

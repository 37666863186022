import React, {useEffect} from 'react';
import styled from 'styled-components';
// import { gsap } from 'gsap';

const StyledParagraph = styled.p`
    color: var(--textTitle);
    margin-bottom: ${({ mb80 }) => mb80 ? '80px' : '20px'};
    margin-top: 0;
    opacity: ${({ opacity0}) => opacity0 ? '0' : 'unset'};
    
    p > span {
        white-space: pre-wrap;
    }
    
    p:last-child {
        margin-bottom: 0;       
    }
`;

const Paragraph = ({ children, mb80, opacity0 }) => {

    useEffect(() => {
        // const P = gsap.utils.toArray('.paragraph');

        // P.forEach((el, index) => {
        //     gsap.fromTo(P[index],{ y: 50 }, { y: 0, duration: 1.5, scrollTrigger: {
        //         trigger: P[index],
        //         //         start: '-10% 75%',
        //         //         end: '-10% 95%',
        //         //             markers: true,
        //     }});
        // });
    }, []);

    return (
        <StyledParagraph className="paragraph" mb80={mb80} opacity0={opacity0}>
            {children}
        </StyledParagraph>
    );
};

export default Paragraph;
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import styled from 'styled-components';
import { gsap } from 'gsap';
import scrollTo from 'gatsby-plugin-smoothscroll';
import HeroSvg from '../../../static/hero_index_graphic_indent_mini.svg';
import Container from "../Container";
import ButtonNoLinking from "../Button/ButtonNoLinking";
import { StyledButton } from '../Button/Button';
import SvgWaveBg from '../../../static/SVG_wave_BG.svg';
// import { GSDevTools } from '../../utils/GSDevTools';

const HeroSection = styled.section`
    min-height: calc(100vh - 107px);
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: 0 1rem;
    
    .svg_wave_bg_wrapper {
        position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        width: 100%;
        height: 100%;
        background: var(--darkSectionBg);
        
        svg {
            width: inherit;
            height: inherit;
        }
    }
    
    @media (max-width: 768px) {
        min-height: auto;
        padding: var(--firstSectionPadding);
        display: grid;
            place-items: center;
            
        .svg_wave_bg_wrapper {
            display: none;
        }
    }
    
`;

const HeroContentWrapper = styled.div`
    display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
    height: calc(90vh - 70px);
  
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        height: unset;
  
        a:first-of-type {
            margin-right: 0;
        }
    }
`;

const TextsAndButtonsWrapper = styled.div`
    display: grid;
        grid-gap: 3rem;
    width: 100%;
    z-index: 2;
   
    .additional_header {
        font-size: 80%;
        color: gray;
        margin: 0;
        text-transform: uppercase;
    }
    
    .main_header {
        font-size: clamp(150%, 10vw, 270%);
        line-height: initial;
        color: var(--textTitle);
        width: 100%;
        margin: 0;
        z-index: 0;
    }
    
    .subheading {
        //opacity: 0;
        margin: 0;
        font-weight: normal;
        line-height: initial;
        
        ul {
            padding-left: 1.5em;
            margin: 0;

            li::marker {
                color: var(--primary);
            }
        }
    }
    
    .button_wrapper {
        //opacity: 0;
    }
    
    .buttons {
        display: flex;
        grid-gap: 2rem;
        
        a {
            width: auto;
            //opacity: 0;
        }
        
        @media (max-width: 940px) {
            width: fit-content;
            flex-direction: column;
            grid-gap: 1rem;
        }
    }
    
    @media (max-width: 768px) {
        grid-area: 1/1;
        order: 2;
    
        .main_header {
            //font-size: 240%;
            width: 100%;
            margin-bottom: 0;
        }
        
        .subheading {
            margin: 0;
            font-size: 120%;
        }
        
        .buttons {
            display: grid;
            text-align: center;
            grid-gap: 10px;
            margin: 0 auto;
            
            a, div {
                min-width: 40vw;
            }
           
            a:first-child {
                margin-right: 0;
            }   
        }
    }
    
    @media (max-width: 460px) {
        //.main_header {
        //    font-size: 230%;
        //}
        
        .buttons a, div {
            min-width: unset;
        }
    }
        
    @media (max-width: 370px) {
        //.main_header {
        //    font-size: 7vw;
        //}
        
        .buttons a, div {
            min-width: unset;
        }
    }
`;

const SvgWrapper = styled.div`
    text-align: center;
    position: relative;
    display: grid;
    place-items: center;
    width: 100%;
    
    @media (max-width: 768px) {
        grid-area: 1/1;
        order: 1;
        opacity: .15;
    }
`;

const StyledHeroSvg = styled(HeroSvg)`
    height: auto;
    visibility: visible!important;
    overflow: visible!important;
    width: inherit;
    
    #mobile_phone, #gear, #protect {
        //visibility: hidden
    }
    
    g#lines path, #arrow-down path #experimental-line path {
        //visibility: hidden;
    }
    
    g#lines path {
        stroke-width: 1;
        stroke: #00e0ff;
        fill: none;
        stroke-linejoin: round;
    }
    
    g#experimental-line path {
        stroke-width: 2;
        opacity: 0;
    }
`;

const PageIndex_Hero = () => {

    const { t } = useTranslation()
    const txtWrapper = useRef();
    const svgWrapper = useRef();

    useEffect(() => {

        // const HeroBtn = document.querySelectorAll('.hero_txt a');
        // const tl1 = gsap.timeline();
        // let split = new SplitText(".hero_txt h1", {type: "chars, words"});
        // tl1.to('.hero_txt h1', {duration: 0, css : {visibility: 'visible'}})
        //     .from(split.chars, {duration: 2, scale:1.5, autoAlpha:0,  rotationX: 0,  transformOrigin:"100% 50%", ease:"back", stagger: 0.06}, '+=.5')
        //     .fromTo(HeroBtn, { autoAlpha: 0, x: -100 }, { autoAlpha: 1, x: 0, stagger: .3, duration: 2.5}, '-=4')

        const Lines = document.querySelectorAll('#experimental-line path'),
              MobilePhone = document.querySelector('#mobile_phone'),
              Gear = document.querySelector('#gear'),
              Protect = document.querySelector('#protect');

        const heroTimeline = gsap.timeline();
        heroTimeline
            .fromTo(MobilePhone, { autoAlpha: 0, x: -30}, { autoAlpha: 1, x: 0, duration: .4}, '-=6')
            .to(MobilePhone, {scrollTrigger: {
                trigger: MobilePhone,
                toggleClass: {targets: MobilePhone, className: 'float_slow_5s'},
            }}, '-=5')
            .fromTo(Gear, { autoAlpha: 0, y: +50}, { autoAlpha: 1, y: 0, duration: .4}, '-=5')
            .to(Gear, {scrollTrigger: {
                    trigger: Gear,
                    toggleClass: {targets: Gear, className: 'float_slow_6s'},
                }})
            .fromTo(Protect, { autoAlpha: 0, y: +50}, { autoAlpha: 1, y: 0, duration: .4}, '-=4')
            .to(Protect, {
                scrollTrigger: {
                    trigger: Protect,
                    toggleClass: {targets: Protect, className: 'float_slow_4s'},
                }
            })
            // .fromTo(Lines,
            //     { autoAlpha: 1 },
            //     { autoAlpha: 0,  duration: .5}
            //     )
            // .fromTo(Lines,
            // { drawSVG:"0", css: { stroke: 'var(--primary)'} },
            // { drawSVG:"100%", css: { stroke: '#00e0ff'}, duration: .5, stagger: .2, delay: .5, yoyo: true}
            // )

        const codeLinesTimeline = gsap.timeline({
            repeat: -1
        });
        codeLinesTimeline
            // .set(Lines, { css: { visibility: 'visible'}})
            .to(Lines, { autoAlpha: 0 })
            .to(Lines, { drawSVG:"0", css: { stroke: '#0076FF'} })
            .to(Lines, { autoAlpha: 1 })
            .to(Lines, { drawSVG:"100%", css: { stroke: '#00e0ff'}, duration: .5, stagger: .2 })
            .to(Lines, { autoAlpha: 0 })
            // .to(Lines, { drawSVG:"0", css: { stroke: '#0076FF'} })
            // .fromTo(Lines,
            // { autoAlpha: 1 },
            // { autoAlpha: 0,  duration: .5}
            // )
            // .to(Lines, { css: { visibility: 'hidden'}})
            // .to(Lines, { drawSVG: "0", duration: 0 })


            // GSDevTools.create();


    }, []);

    // useEffect(() => {
    //     const heroCTAtl = gsap.timeline();
    //     heroCTAtl
    //         // .fromTo('.subheading', {x: -50, autoAlpha: 0}, {x: 0, autoAlpha: 1, delay: .2})
    //         .fromTo('.read_more', {autoAlpha: 0, x: -50},  { autoAlpha: 1, x: 0 })
    //         .fromTo('.estimate', {autoAlpha: 0,x: 50}, {autoAlpha: 1, x: 0})
    //
    // }, []);

    return (
        <HeroSection>
            <Container>
                <HeroContentWrapper>
                    <TextsAndButtonsWrapper className="hero_txt" useRef={txtWrapper} >
                        <h1 className="main_header">
                            {t('hero.text_h1')}
                        </h1>
                        <h2 className="subheading">
                            <ul>
                                <li>Jamstack,</li>
                                {/*<li>{t('hero.subheading_skill_2')},</li>*/}
                                <li>SEO,</li>
                                {/*<li>{t('hero.subheading_skill_3')},</li>*/}
                                <li>{t('hero.subheading_skill_4')},</li>
                                <li>marketing.</li>
                                {/*<li>{t('hero.subheading_skill_5')}.</li>*/}
                            </ul>
                        </h2>
                        <div className="buttons">
                            <StyledButton role="button" tabIndex="0" filled={+true} to="/wycena/" className="estimate">
                                {t('hero.estimate_project_btn')}
                            </StyledButton>
                            <ButtonNoLinking role="button" tabIndex="0" darkBorder classNameProp="read_more" Fn={() => scrollTo('.i_will_help_you')}>
                                {t('hero.find_out_more')}
                            </ButtonNoLinking>
                        </div>
                    </TextsAndButtonsWrapper>
                    <SvgWrapper ref={svgWrapper}>
                        <StyledHeroSvg/>
                    </SvgWrapper>
                </HeroContentWrapper>
            </Container>
            <div className="svg_wave_bg_wrapper">
                <SvgWaveBg />
            </div>
        </HeroSection>
    )
};

export default PageIndex_Hero;
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next";
import { Link } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from "../Container";
import SectionHeader from "../Typography/SectionHeader";
import { Section } from './index';
import { cooperateSteps } from '../../data/cooperateSteps';
import { StaticImage } from "gatsby-plugin-image"

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
    grid-gap: 3rem;
    counter-reset: cooperateSteps;
    
    .icon_and_title_wrapper {
        display: grid;
        grid-gap: 1rem;
    }
    
    .icon_wrapper, .title, .desc {
        opacity: 0;
    }
    
    .icon_wrapper {
        display: inline-flex;
            justify-content: flex-start;
        
        svg {
            filter: grayscale(.5);
            //height: clamp(30px, 50px, 65px);
            height: clamp(25px,35px,45px);
            width: auto;
        }
    }
    
    h3 {
        color: var(--white);
        margin: 0;
        
        &::before {
            counter-increment: cooperateSteps;
            content: ""counter(cooperateSteps)". ";
        }
        
        span {
            font-size: 1.5rem;
        }
        
        span.number {
            white-space: pre-wrap;
        }
        
        span.dot {
            color: var(--primary);
        }
    }
    
    p, a {
        color: var(--gray);
        text-decoration: none;
    }
    
    @media (max-width: 768px) {
        .icon_and_title_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            h3 {
                margin: 0;
            }
        }
        
        .icon_wrapper {
            order: 2;
        }
    }
`;

const BackgroundImage = styled.div`
    display: grid;
    grid-area: 1/1;
    filter: brightness(.25);
`

const ContentOnBackgroundImage = styled.div`
    display: grid;
    grid-area: 1/1;
    padding: var(--sectionPadding);
`

const HowDoICooperate = () => {

    const { t } = useTranslation();
    const howDoICooperateRef = useRef();

    useEffect(() => {
        const prf = gsap.utils.toArray('.step');
        gsap.set(['.desc, icon_wrapper, .title'], { ease: 'power4.out', autoAlpha: 0 });

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": function() {
                gsap.set('.icon_wrapper', { y: 30 })
                gsap.set('.title', { x: 50 })
                gsap.set('.desc', { y: 30 })

                prf.forEach((item, i) => {
                    item.tl = gsap.timeline({ paused: true, delay: i / 6 + 0.1 })
                        .to(item.querySelector('.icon_wrapper'), { autoAlpha: 1, y: 0, duration: .5, })
                        .to(item.querySelector('.title'), { autoAlpha: 1, x: 0, duration: .5 }, '-=.35')
                        .to(item.querySelector('.desc'), { autoAlpha: 1, y: 0, duration: .5, }, '-=.35')
                });

                function show(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.paused(true).delay(i / 3 + .1).play();
                    });
                }
                function reverse(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.pause(i / 3 + 0.1).reverse();
                    });
                }

                const startAndEnd = '-10% 75%';
                ScrollTrigger.batch(prf, {
                    onEnter: show,
                    onEnterBack: reverse,
                    start: startAndEnd,
                    end: startAndEnd,
                });
            },
            "(max-width: 768px)": function() {

                gsap.set('.icon_wrapper', { y: 30 })
                gsap.set('.title', { x: 50 })
                gsap.set('.desc', { y: 30 })

                prf.forEach((item, i) => {
                    item.tl = gsap.timeline({ paused: true })
                        .to(item.querySelector('.icon_wrapper'), { autoAlpha: 1, y: 0, duration: .5, })
                        .to(item.querySelector('.title'), { autoAlpha: 1, x: 0, duration: .5 }, '-=.35')
                        .to(item.querySelector('.desc'), { autoAlpha: 1, y: 0, duration: .5, }, '-=.35')
                });

                function show(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.play();
                    });
                }
                function reverse(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.reverse();
                    });
                }

                const startAndEnd = '-10% 75%';
                ScrollTrigger.batch(prf, {
                    onEnter: show,
                    onEnterBack: reverse,
                    start: startAndEnd,
                    end: startAndEnd,
                });
            },
        });
    }, [])

    return (
        <Section grid darker noPadding className="coop" ref={howDoICooperateRef}>
            <BackgroundImage>
                <StaticImage
                    src="../../../static/cooperation.jpg"
                    layout="fullWidth"
                    alt=""
                    aspectRatio={4 / 1}
                    formats={["auto", "webp", "avif"]}
                />
            </BackgroundImage>
            <ContentOnBackgroundImage>
                <Container noPadding>
                    <SectionHeader biggerMarginBottom center darkUnderline>
                        {t('how_do_i_cooperate.header_line_1')}
                        <span className="underlined">
                            {t('how_do_i_cooperate.header_line_2')}
                        </span>
                        <span role="img" aria-label="emoji"> 🤝</span>
                    </SectionHeader>
                    <GridWrapper>
                        {cooperateSteps.map(step => (
                            <div key={step.number} className="step">
                                <div className="icon_and_title_wrapper">
                                    <div className="icon_wrapper">{step.icon}</div>
                                    <div className="title">
                                        <h3>
                                            {/*<span className="dot">. </span>*/}
                                            {t(step.title)}
                                        </h3>
                                    </div>
                                </div>
                                <div className="desc">
                                    <p>
                                        {t(step.description)}
                                        {step.number === 2 &&
                                            <>
                                                {' '}<Link className="underscored" to="/wycena/">
                                                {t('how_do_i_cooperate.valuation_form')}
                                                </Link>
                                            </>
                                        }
                                    </p>
                                </div>
                            </div>
                        ))}
                    </GridWrapper>
                </Container>
            </ContentOnBackgroundImage>
        </Section>
    )
};

export default HowDoICooperate;

import styled  from 'styled-components';

export const SvgWrapper = styled.div`
    display: grid;
    place-items: center;
    padding: ${({ revert_order }) => revert_order ? '0 2rem 0 0' : '0 0 0 2rem'};
        
    @media (max-width: 980px) {
        order: ${({ revert_order }) => revert_order ? '2' : 'unset'};
        padding: 0;
    
        svg {
            width: 50%;
            margin: 0 auto;
        }
    }
    
    @media (max-width: 420px) {
        svg {
            width: 80%;
        }
    }
    
    .svg_wrapper svg #man, .svg_wrapper svg #hologram, .svg_wrapper svg #list_left, .svg_wrapper svg #graph_right, .svg_wrapper2 svg #sitting_man, .svg_wrapper2 svg #plant, .svg_wrapper2 svg #board {
        opacity: 0;
    }
`;
import React, { useEffect } from "react"
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import Container from "../Container"
import SectionHeader from "../Typography/SectionHeader"
import { gsap } from "gsap"

const StyledStatsSection = styled.section`
    padding: var(--sectionPadding);
    background: var(--primary);
    background: linear-gradient(90deg, var(--primary) 0%, rgba(32,57,175,1) 100%);
    color: white;
    
    h2 {
        margin: 0 0 1rem;
    }
    
    p {
        margin: 0;
    }
    
    a {
        color: inherit;
        font-weight: 800;
        text-decoration: underline;
            text-decoration-color: var(--advSvg);
            text-decoration-thickness: 3px;
            text-decoration-skip-ink: none;
    }
    
    .stats_row {
        display: flex;
            align-items: center;
            justify-content: space-between;
        margin-top: 2rem;
        
        .effects_stats {
            display: flex;
               justify-content: space-between;
            width: 100%;
            
            @media (max-width: 768px) {
                display: grid;
                    justify-content: center;
                    grid-gap: 2rem;
            }
            
            .single_stat {
                max-width: 210px;
                text-align: center;
                width: inherit;
                display: grid;
                
                .num_wrapper {
                    line-height: 1.1;
                }
                
                span {
                    font-weight: bold;
                    font-size: 350%;
                }
            }
        }
    }
    
`;

const WPOStats = () => {

    const { t } = useTranslation();

    useEffect(() => {
        // gsap.to('.stat__number span', { autoAlpha: 1, scrollTrigger: {
        //         trigger: '.stat_wrapper',
        //         start: '-30% 80%',
        //         end: '-30% 80%',
        //         markers: true,
        //         // onEnter: countUp()
        //     }
        // })
        gsap.from('.single_stat span.num', {
            duration: 1.5,
            ease:"none",
            innerHTML:"0",
            roundProps:"innerHTML",
            scrollTrigger: {
                trigger: '.stats_row',
                start: '-30% 80%',
                end: '-30% 80%',
            }
        });
    }, []);

    return (
        <StyledStatsSection>
            <Container>
                <SectionHeader>{t('wpo_stats.header')} <span role="img" aria-label="emoji"> 🚀</span></SectionHeader>
                <p>
                    {t('wpo_stats.paragraph')}{' '}
                    <a href="https://wpostats.com/2016/12/27/aliexpress-load-time.html">WPOstats.com</a>
                </p>
                <div className="stats_row">
                    {/*<div className="catalyst_stat">*/}
                        {/*<div className="arrow_wrapper">*/}
                        {/*    <Arrow />*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    <div className="effects_stats">
                        <div className="single_stat">
                            <div className="num_wrapper">
                                <span className="num">36</span><span>% </span>
                                {/*<span>⬇️</span>*/}
                            </div>
                            <p>{t('wpo_stats.stat0')}</p>
                        </div>
                        <div className="single_stat">
                            <div className="num_wrapper">
                                <span className="num">10.5</span><span>% </span>
                                {/*<span>⬆️</span>*/}
                            </div>
                            <p>{t('wpo_stats.stat1')}</p>
                        </div>
                        <div className="single_stat">
                            <div className="num_wrapper">
                                <span className="num">27</span><span>% </span>
                                {/*<span>⬆️</span>*/}
                            </div>
                            <p>{t('wpo_stats.stat2')}</p>
                        </div>
                    </div>
                </div>
            </Container>
        </StyledStatsSection>
    )
}

export default WPOStats;
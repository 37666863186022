import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from "../Container";
import SectionHeader from "../Typography/SectionHeader";
import { Section, TextWrapper } from './index';
import { useTranslation } from "react-i18next"

const BackgroundImageWrapper = styled.div`
    display: grid;
    grid-area: 1/1;
    filter: brightness(.25);
`

const ContentOnBackgroundImage = styled.div`
    display: grid;
    grid-area: 1/1;
    position: relative;
    padding: var(--sectionPadding);
    
    .gatsby-image-wrapper > div[aria-hidden="true"] {
        display: none!important
    }
    
    .gatsby-image-wrapper > div[aria-hidden="true"] {
        display: none;
    }   

`

const GridProfitWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    grid-gap: 25px;
    overflow-y: hidden; 
`;

const ProfitWrapper = styled.div`
    display: grid;
    grid-template-columns: 25% 1fr;
    overflow: hidden;
    
    .name {
        opacity: 0;
    }
    
    h3 {
        color: var(--white);
    }
    
    p {
        color: var(--gray);
    }
    
    @media (max-width: 768px) {
        display: block;
    }
`

const ProfitNumber = styled.span`
    font-size: 700%;
    opacity: 15%;
    color: var(--white);
    font-weight: 800;
    
    @media (max-width: 768px) {
       display: none;
    }
`

const ProfitName = styled.h3`
    display: inline;
`

const JamstackForYourBusiness = () => {

    const { t } = useTranslation();

    const profits = [
        {
            number: 1,
            name: 'for_business.1_performance',
            desc: 'for_business.1_performance_desc',
        },
        {
            number: 2,
            name: 'for_business.5_modularity',
            desc: 'for_business.5_modularity_desc',
        },
        {
            number: 3,
            name: 'for_business.3_low_costs',
            desc: 'for_business.3_low_costs_desc',
        },
        {
            number: 4,
            name: 'for_business.4_scalability',
            desc: 'for_business.4_scalability_desc',
        }
    ]

    useEffect(() => {

        const prf = gsap.utils.toArray('.prf');
        gsap.set(['.prf .name, .prf .desc, .prf .number'], { autoAlpha: 0, ease: 'power4.out' });
        gsap.set('.prf .name', { x: 50 })
        gsap.set('.prf .desc', { y: 50 })

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": function() {
                gsap.set('.icon_wrapper', { y: 30 })
                gsap.set('.title', { x: 50 })
                gsap.set('.desc', { y: 30 })

                prf.forEach((item, i) => {
                item.tl = gsap.timeline({
                    paused: true,
                    delay: i / 12 + 0.1
                })
                    .to(item.querySelector('.prf .number'), { autoAlpha: .1, duration: .5, })
                    .to(item.querySelector('.prf .name'), { x: 0, autoAlpha: 1, duration: .5 }, '-=.35')
                    .to(item.querySelector('.prf .desc'), { y: 0, autoAlpha: 1, duration: .5, }, '-=.35')
                });

                function show(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.paused(true).delay(i / 6 + 0.1).play();
                    });
                }

                function reverse(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.pause(i + 0.5).reverse();
                    });
                }

                const startAndEnd = '-10% 75%';
                ScrollTrigger.batch(prf, {
                    onEnter: show,
                    onEnterBack: reverse,
                    start: startAndEnd,
                    end: startAndEnd,
                });
            },
            "(max-width: 768px)": function() {
                prf.forEach((item, i) => {
                    item.tl = gsap.timeline({
                        paused: true,
                    })
                      .to(item.querySelector('.prf .number'), { autoAlpha: .05, duration: .5, })
                      .to(item.querySelector('.prf .name'), { x: 0, autoAlpha: 1, duration: .5 }, '-=.35')
                      .to(item.querySelector('.prf .desc'), { y: 0, autoAlpha: 1, duration: .5, }, '-=.35')
                });

                function show(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.play();
                    });
                }

                function reverse(batch) {
                    batch.forEach(function(target, i) {
                        target.tl.reverse();
                    });
                }

                const startAndEnd = '-10% 75%';
                ScrollTrigger.batch(prf, {
                    onEnter: show,
                    onEnterBack: reverse,
                    start: startAndEnd,
                    end: startAndEnd,
                });
            }
        });
    })

    return (
        <Section noPadding grid darker className="section jamstack_for_your_business">
            <BackgroundImageWrapper className="bg">
                <StaticImage
                    src="../../../static/business_bg_image.jpg"
                    layout="fullWidth"
                    alt=""
                    aspectRatio={3 / 1}
                    formats={["auto", "webp", "avif"]}
                />
            </BackgroundImageWrapper>
            <ContentOnBackgroundImage>
                <Container noPadding>
                    <TextWrapper center>
                        <SectionHeader mbx2 darkUnderline biggerMarginBottom>
                            {t('for_business.header_line_1')}
                            {' '}<span className="underlined">{t('for_business.header_line_2')}</span>
                            {' '}<span role="img" aria-label="emoji">📈</span>
                        </SectionHeader>
                    </TextWrapper>
                    <GridProfitWrapper>
                        {profits.map(profit => (
                            <ProfitWrapper key={profit.number} className="prf">
                                <ProfitNumber className="number">
                                    <span>
                                      {profit.number}
                                    </span>
                                </ProfitNumber>
                                <div>
                                    <div className="name">
                                        <ProfitName className="underscored">
                                          {t(profit.name)}
                                        </ProfitName>
                                    </div>
                                    <p className="desc">
                                      {t(profit.desc)}
                                    </p>
                                </div>
                            </ProfitWrapper>
                        ))}
                    </GridProfitWrapper>
                </Container>
            </ContentOnBackgroundImage>
        </Section>
    )
};

export default JamstackForYourBusiness;


import React from "react"
import { graphql } from "gatsby"
import Layout from '../layout/layout';
import Seo from "../components/seoComponent"
import Hero from '../components/PageIndex/Hero';
import IWillHelpYouWith from '../components/PageIndex/IWillHelpYouWith';
import JamstackForWebsites from "../components/PageIndex/JamstackForWebsites";
import WPOStatisticsSection from "../components/PageIndex/WPOStatisticsSection"
import JamstackForYourBusiness from "../components/PageIndex/JamstackForYourBusiness";
import LatelyOnBlog from '../components/PageIndex/LatelyOnBlog';
import WhatElseCanIDo from "../components/PageIndex/WhatElseCanIDo";
import HowDoICooperate from "../components/PageIndex/HowDoICooperate"
import { useTranslation } from "react-i18next"
import { websiteUrl } from "../data/navItems"

const IndexPage = ({ location }) => {

    const url = `${websiteUrl + location.pathname}`;
    const { i18n } = useTranslation();
    const locale = i18n.language;

    return (
        <Layout>
            <Seo
                title="Buduję strony i aplikacje, które przynoszą zysk"
                description="Jamstack, optymalizacja Front-Endu, SEO, usługi chmurowe, automatyzacje, marketing internetowy."
                pageUrl={url}
                pageName={location.pathname.slice(1,-1)}
            />
            <Hero />
            <IWillHelpYouWith />
            <JamstackForWebsites />
            <WPOStatisticsSection />
            <JamstackForYourBusiness />
            <WhatElseCanIDo />
            <HowDoICooperate />
            {locale === 'pl' &&
                <LatelyOnBlog />
            }
        </Layout>
    )
};

export const query = graphql`
    query {
        file(name: {eq: "form_start"}) {
            childImageSharp {
                resize(width: 1200) {
                    src
                    height
                    width
                }
            }
        }
    }
`;

export default IndexPage;

import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import Link from 'gatsby-link';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from "../Container";
import Paragraph from "../Typography/Paragraph";
import Span from '../Typography/Span';
import SectionHeader from "../Typography/SectionHeader";
import SvgHologram from '../../../static/hologram_graphic.svg';
import { Section, TextWrapper, SvgWrapper, GridContainer } from './index';

const JamstackForWebsites = () => {

    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    useEffect(() => {
        const Man = document.querySelector('.svg_wrapper svg #man');
        const Hologram = document.querySelector('.svg_wrapper svg #hologram');
        const ListLeft = document.querySelector('.svg_wrapper svg #list_left');
        const GraphRight = document.querySelector('.svg_wrapper svg #graph_right');

        gsap.set([Man, Hologram, ListLeft, GraphRight], { autoAlpha: 0, ease: 'power4.out' });
        gsap.set([Man, GraphRight], { x: 50 });
        gsap.set([Hologram, ListLeft], { x: -50 });

        const tl = gsap.timeline({ paused: true });
        tl
          .to(Man, { autoAlpha: 1, x: 0, duration: .5 })
          .to(Hologram, { autoAlpha: 1, x: 0, duration: .5 })
          .to(ListLeft, { autoAlpha: 1, x: 0, duration: .5 })
          .to(GraphRight, { autoAlpha: 1, x: 0, duration: .5 })

        ScrollTrigger.create({
            trigger: '.svg_wrapper',
            start: '15% 75%',
            end: '15% 75%',
            onEnter: () => tl.play(),
            onEnterBack: () => tl.reverse(),
        });
    }, [])

    return (
        <Section className="what_is_jamstack">
            <Container noPadding>
                <GridContainer>
                    <TextWrapper>
                        <SectionHeader dark>
                            {t('jamstack.header_line_1')}
                            <span className="underlined">{t('jamstack.header_line_2')}</span>
                            {locale === 'pl' && <>{' '}<span role="img" aria-label="emoji"> 📚</span></>}
                            {locale === 'en' && <>architecture <span role="img" aria-label="emoji"> 📚</span></>}
                        </SectionHeader>
                        <Paragraph>
                            <Span>{t('jamstack.what_is_span_1')}</Span>
                            <Span>{t('jamstack.what_is_span_3')}</Span>
                            <Span>{t('jamstack.what_is_span_2')}</Span>
                            <Span>{t('jamstack.what_is_span_4')}</Span>
                            <Span>
                                {t('jamstack.what_is_span_5')}
                                <span>{" "}</span>
                                {locale === 'pl' && <><Link className="underscored" to="/blog/jamstack-101">tutaj.</Link></>}
                                {locale === 'en' && <><Link className="underscored" to="/blog/jamstack-101">here.</Link></>}
                            </Span>
                        </Paragraph>
                    </TextWrapper>
                    <SvgWrapper className="svg_wrapper">
                        <SvgHologram />
                    </SvgWrapper>
                </GridContainer>
            </Container>
        </Section>
    )
};

export default JamstackForWebsites;


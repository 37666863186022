import React from "react"
import Step1Icon from "../../static/coop_step_1.svg"
import Step2Icon from "../../static/coop_step_2.svg"
import Step3Icon from "../../static/coop_step_3.svg"
import Step4Icon from "../../static/coop_step_4.svg"
import Step5Icon from "../../static/coop_step_5.svg"
import Step6Icon from "../../static/coop_step_6.svg"

export const cooperateSteps = [
    {
        number: 1,
        title: 'how_do_i_cooperate.step1',
        description: 'how_do_i_cooperate.step1_desc',
        icon: <><Step1Icon/></>
    },
    {
        number: 2,
        title: 'how_do_i_cooperate.step2',
        description: 'how_do_i_cooperate.step2_desc',
        icon: <><Step2Icon/></>
    },
    {
        number: 3,
        title: 'how_do_i_cooperate.step3',
        description: 'how_do_i_cooperate.step3_desc',
        icon: <><Step3Icon/></>
    },
    {
        number: 4,
        title: 'how_do_i_cooperate.step4',
        description: 'how_do_i_cooperate.step4_desc',
        icon: <><Step4Icon/></>
    },
    {
        number: 5,
        title: 'how_do_i_cooperate.step5',
        description: 'how_do_i_cooperate.step5_desc',
        icon: <><Step5Icon/></>
    },
    {
        number: 6,
        title: 'how_do_i_cooperate.step6',
        description: 'how_do_i_cooperate.step6_desc',
        icon: <><Step6Icon/></>
    },
]
import React, { useEffect } from "react"
import styled from 'styled-components';
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import Container from "../Container"
import SectionHeader from "../Typography/SectionHeader"
import Li from "../Typography/Li"
import useReactSimpleMatchMedia from 'react-simple-matchmedia'

const IWillHelpYouWithStyles = styled.section`
    background: linear-gradient(180deg, var(--darkSectionBg) 50%, rgb(255, 255, 255) 50%);  
    padding: var(--sectionPadding);  
    scroll-padding-top: 10rem;
    
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        //background: var(--lightSectionBg);
        border-radius: 1rem;
        box-shadow: 0 15px 25px -15px rgba(0,0,0,.25);
        overflow-x: hidden;
    }
    
    .image_wrapper {
    
        img {
            border-radius: 1rem 0 0 1rem;
        }
    }
    
    .text_wrapper {
        display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            order: 2;
        color: var(--black);
        background: var(--lightSectionBg);
        border-radius: 0 1rem 1rem 0;
                
        h2 {
            margin: 0 auto;
        }
    }
    
    @media (max-width: 980px) {
        padding: var(--sectionPaddingHalf);

        .container {
            grid-template-columns: 1fr;
        }
        
        .image_wrapper img {
            border-radius: 1rem 1rem 0 0;
        }
        
        .text_wrapper {
            padding: 1rem;
            border-radius: 0 0 1rem 1rem;
            
            ul {
                padding-left: 1.25em;
            }
        }
    }
`;

const IWillHelpYouWith = () => {

    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    useEffect(() => {

        // gsap.set(['.i_will_help_you .image_wrapper, .i_will_help_you .text_wrapper'], {autoAlpha: 0, duration: 1.5, ease: 'power4.out'});
        // gsap.set('.i_will_help_you .image_wrapper', {x: -100});
        // gsap.set('.i_will_help_you .text_wrapper', {x: 100});

        // gsap.to('.i_will_help_you .image_wrapper', {
        //     scrollTrigger: {
        //         trigger: '.i_will_help_you .container',
        //         start: '0 80%',
        //         end: '0 80%',
        //         onEnter: () => gsap.to('.i_will_help_you .image_wrapper', { x: 0, autoAlpha: 1 }),
        //         onEnterBack: () => gsap.to('.i_will_help_you .image_wrapper', { x: -100, autoAlpha: 0 }),
        //         // markers: true,
        //     }
        // })
        // gsap.to('.i_will_help_you .text_wrapper', {
        //     scrollTrigger: {
        //         trigger: '.i_will_help_you .container',
        //         start: '0 80%',
        //         end: '0 80%',
        //         onEnter: () => gsap.to('.i_will_help_you .text_wrapper', { x: 0, autoAlpha: 1 }),
        //         onEnterBack: () => gsap.to('.i_will_help_you .text_wrapper', { x: 100, autoAlpha: 0 }),
        //         // markers: true,
        //     }
        // })


        // gsap.fromTo('.text_wrapper ul', { autoAlpha: 0, y: 25 }, {
        //     autoAlpha: 1,
        //     y: 0,
        //     ease: 'power4.out',
        //     scrollTrigger: {
        //         trigger: '.text_wrapper ul',
        //         start: startAndEnd,
        //         end: startAndEnd,
        //         onEnter: () => gsap.to('.text_wrapper ul', { autoAlpha: 1, y: 0, duration: .5 }),
        //         onEnterBack: () => gsap.to('.text_wrapper ul', { autoAlpha: 0, y: 25, duration: .5}),
        //     }
        // });
    }, [])

    const isDesktop = useReactSimpleMatchMedia('(min-width: 980px)');

    return (
        <IWillHelpYouWithStyles className="i_will_help_you section2">
            <Container noPadding>
                <div className="image_wrapper">
                    {isDesktop &&
                        <StaticImage
                            src="../../../static/wQ4.jpg"
                            layout="fullWidth"
                            alt=""
                            aspectRatio={3 / 2}
                            formats={["auto", "webp", "avif"]}
                        />
                    }
                </div>
                <div className="text_wrapper">
                    <SectionHeader dark>
                        <span className="underlined">
                            {t('i_will_help_you.header_line_1')}
                        </span>{locale === 'pl' && ','}{' '}
                        {t('i_will_help_you.header_line_2')}
                        :
                    </SectionHeader>
                    <ul>
                        <Li>{t('i_will_help_you.helper_1')},</Li>
                        <Li>{t('i_will_help_you.helper_2')},</Li>
                        <Li>{t('i_will_help_you.helper_3')},</Li>
                        <Li>{t('i_will_help_you.helper_4')},</Li>
                        <Li>{t('i_will_help_you.helper_5')},</Li>
                        <Li>{t('i_will_help_you.helper_6')}.</Li>
                    </ul>
                </div>
            </Container>
        </IWillHelpYouWithStyles>
    )

}

export default IWillHelpYouWith;
import styled from "styled-components";

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ reverted }) => reverted ? '40% 60%' : '60% 40%'};
    
    @media (max-width: 980px) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }
`;
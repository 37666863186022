import styled from 'styled-components'
import { ButtonStyles } from './ButtonStyles';
import { Link } from 'gatsby';

export const StyledButton = styled(Link)`
    ${ButtonStyles};
    // padding: 0;
    
    a {
        padding: .5em 1.5em;
        position: relative;
            z-index: 2;
    }
`;

//
// const Button = ({ children, linking, filled, Fn, classNameProp, invisibleForAnimation, marginTop }) => {
//
//     return (
//         <StyledButton
//             className={classNameProp}
//             onClick={Fn}
//             filled={filled}
//             invisibleForAnimation={invisibleForAnimation}
//             marginTop={marginTop}
//         >
//                 <Link to={`/${linking}`}>
//                     {children}
//                 </Link>
//         </StyledButton>
// )};
//
// export default Button;
import React, { useEffect, useRef } from "react"
import styled from 'styled-components';
import { gsap } from 'gsap';

const StyledSpan = styled.span`
    color: var(--textTitle);
    margin-bottom: ${({ mb80 }) => mb80 ? '80px' : '.75rem'};
    opacity: 0;
    
    span {
        white-space: pre-wrap;
    }
`;

const Span = ({ children, mb80, opacity0 }) => {

    const SpanRef = useRef();

    useEffect(() => {
        const span = gsap.utils.toArray(SpanRef.current);
        gsap.set(span, { autoAlpha: 0, duration: .5, y: 25 });
        span.forEach((el, index) => {
            gsap.set(span[index], { duration: .5 })
            gsap.to(span[index], { scrollTrigger: {
                trigger: span[0],
                start: '-10% 95%',
                end: '-10% 95%',
                // toggleActions: "restart none none none",
                onEnter: () => gsap.to(span[index], { y: 0, autoAlpha: 1, duration: .5 }),
                onEnterBack: () => gsap.to(span[index], { y: 25, autoAlpha: 0, duration: .5 }),
            }});
        });
  }, []);

  return (
    <StyledSpan ref={SpanRef} className="span" mb80={mb80} opacity0={opacity0}>
      {children}
    </StyledSpan>
  );
};

export default Span;
import React from 'react';
import { ButtonA } from './ButtonA';

const SectionHeading = ({ children, linking, filled, link, Fn, classNameProp, darkBorder, invisibleforanimation }) => {

    return (
        <>
            <ButtonA className={classNameProp} href={link} onClick={Fn} filled={filled} to={linking} invisibleforanimation={invisibleforanimation} darkBorder={darkBorder} >
                 {children}
            </ButtonA>
        </>
    )
};

export default SectionHeading;
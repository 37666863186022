import styled  from 'styled-components';

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    align-items: ${({ center }) => center && 'center'};
    
    .open_line {
        margin: 0 0 1rem;
        opacity: 0;
    }
    
    span {
        display: inline-block;
    }
    
    span:last-of-type {
        margin-bottom: 0;
    }
`;
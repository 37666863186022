import React, { useEffect } from "react"
import styled  from 'styled-components';
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from '../Container'
import SectionHeader from "../Typography/SectionHeader";
import DateIcon from "../../../static/date.svg";
import ShadowOnHover from "../../css/ShadowOnHover"
import formatDate from "../../utils/formatDate"

const LatelyOnBlogSection = styled.section`
    background: transparent;
    padding: var(--sectionPadding);
`;

const PostsWrapper = styled.div`

    ol {
        display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
            grid-column-gap: 40px;
            grid-gap: 1rem;
        list-style: none;
        padding-left: 0;
        
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
        
        li {
            display: grid;
            transition: .25s ease-out;
            
            &:hover {
                transform: translateY(-5px);
            }
        }
      
        .article {
            ${ShadowOnHover};
            display: grid;
            flex-direction: column;
            border-radius: 1rem;
            opacity: 0;
                       
            a {
                display: grid;
                    place-items: center;
                    grid-template-columns: auto;
                    grid-gap: 1rem;
                    align-content: space-between;
                height: 100%;
                text-decoration: none;
                color: var(--white);
            }
            
            h2 {
                font-size: clamp(1.1rem,5vw,1.5rem);
            }
         
            h2, p {
                color: inherit;
                text-align: center;
                word-break: keep-all;
                margin: 0;
                transition: color .3s ease-in;
            }
        }
        
        .gatsby-image-wrapper {
            grid-area: 1/1;
            filter: brightness(.5);
            border-radius: inherit;
            background: #1b1b1b;
        }
    }
`;

const PostTextWrapper = styled.div`
    padding: 2rem 1rem;
    flex-grow: 1;
    display: grid;
        grid-area: 1/1;
    z-index: 1;
    border-radius: inherit;
    
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        word-break: keep-all;
    }
    
    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        fill: var(--primary);
    }
`;

const LatelyOnBlog = () => {

    const { t, i18n } = useTranslation()
    const locale = i18n.language;

    useEffect(() => {
        const articles = gsap.utils.toArray('.article');
        gsap.set(articles, { y: 50, autoAlpha: 0, ease: 'power4.out', duration: .2 })

        ScrollTrigger.batch(".article", {
            trigger: '.article',
            start: '-25% 85%',
            end: '-25% 85%',
            onEnter: batch => gsap.to(batch, { y: 0, autoAlpha: 1, stagger: .2 }),
            onEnterBack: batch => gsap.to(batch, { y: 50, autoAlpha: 0, stagger: .2 }),
        });
    }, [locale]);

    const data = useStaticQuery(graphql`
        query LatelyOnBlogQuery {
            plmd: allMdx(sort: {fields: [frontmatter___date], order: DESC}, limit: 3, filter: {frontmatter: {lang: {eq: "pl"}}}) {
                edges {
                    node {
                        frontmatter {
                            date
                            title
                            lang
                            category
                            featuredImage {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        height: 150
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                            }
                        }
                        fields {
                            slug
                        }
                        id
                    }
                }
            }
            enmd: allMdx(sort: {fields: [frontmatter___date], order: DESC}, limit: 3, filter: {frontmatter: {lang: {eq: "en"}}}) {
                edges {
                    node {
                        frontmatter {
                            date
                            title
                            lang
                            category
                            featuredImage {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        height: 150
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                            }
                        }
                        fields {
                            slug
                        }
                        id
                    }
                }
            }
        }
    `);


    return (
        <LatelyOnBlogSection>
            <Container noPadding>
                <SectionHeader biggerMarginBottom center dark>
                    {t('lately_on_blog.header_line_1')}
                    <span className="underlined">
                        {t('lately_on_blog.header_line_2')}
                    </span>
                    <span role="img" aria-label="emoji"> 🖊</span>
                </SectionHeader>
                <PostsWrapper>
                    {locale === 'pl' &&
                    <ol>
                        {data.plmd.edges.map(edge => {
                            const image = getImage(edge.node.frontmatter.featuredImage);
                            return (
                                <li key={edge.node.id}>
                                    <div className="article">
                                        <GatsbyImage image={image} alt={`Artykuł: ${edge.node.frontmatter.title}`}/>
                                        <PostTextWrapper>
                                            <Link tabIndex="0" role="button" to={`/blog/${edge.node.fields.slug}`}>
                                            <h2>{edge.node.frontmatter.title}</h2>
                                            <div><DateIcon />{formatDate(edge.node.frontmatter.date, edge.node.frontmatter.lang)}</div>
                                            {/*<Link to={'/kategorie/' + edge.node.frontmatter.category}>*/}
                                            {/*  #{edge.node.frontmatter.category}*/}
                                            {/*</Link>*/}
                                            </Link>
                                        </PostTextWrapper>
                                    </div>
                                </li>
                            )
                        })}
                    </ol>
                    }
                    {locale === 'en' &&
                    <ol>
                        {data.enmd.edges.map(edge => {
                            const image = getImage(edge.node.frontmatter.featuredImage);
                            return (
                                <li key={edge.node.id}>
                                    <div className="article">
                                        <GatsbyImage image={image} alt={`Artykuł: ${edge.node.frontmatter.title}`}/>
                                        <PostTextWrapper>
                                            <Link to={`/blog/${edge.node.fields.slug}`}>
                                            <h2>{edge.node.frontmatter.title}</h2>
                                            <div><DateIcon/><p>{edge.node.frontmatter.date}</p></div>
                                            {/*<Link to={'/kategorie/' + edge.node.frontmatter.category}>*/}
                                            {/*  #{edge.node.frontmatter.category}*/}
                                            {/*</Link>*/}
                                            </Link>
                                        </PostTextWrapper>
                                    </div>
                                </li>
                            )
                        })}
                    </ol>
                    }
                </PostsWrapper>
            </Container>
        </LatelyOnBlogSection>
    )
};

export default LatelyOnBlog;
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Container from "../Container";
import SectionHeader from "../Typography/SectionHeader";
import Li from '../Typography/Li';
import Svg from '../../../static/man_sitting_desk.svg';
import { Section, TextWrapper, SvgWrapper, GridContainer } from './index';

const StyledList = styled.ol`
    list-style-type: disc;
    
    @media (max-width: 480px) {
        ul {
            padding-left: 1.25em;
        }
    }
`;

const skills = [
    'what_else.skill_1',
    'what_else.skill_2',
    'what_else.skill_3',
    'what_else.skill_4',
    'what_else.skill_5',
]

const WhatElseCanIDo = () => {

    const { t } = useTranslation();

    useEffect(() => {
        gsap.set('.open_line', { autoAlpha: 0, ease: 'power4.out', duration: 1 })
        gsap.to('.open_line', { scrollTrigger: {
                trigger: '.other_services',
                start: '10% 80%',
                end: '10% 80%',
                onEnter: () => gsap.to('.open_line', { autoAlpha: 1 }),
                onEnterBack: () => gsap.to('.open_line', { autoAlpha: 0 }),
            }
        })
    }, [])

    useEffect(() => {
        const ManSitting = document.querySelector('.svg_wrapper2 svg #sitting_man');
        const Plant = document.querySelector('.svg_wrapper2 svg #plant');
        const Board = document.querySelector('.svg_wrapper2 svg #board');

        gsap.set([ManSitting, Plant, Board], { autoAlpha: 0, ease: 'power4.out' });
        gsap.set(ManSitting, { x: 50 });
        gsap.set(Plant, { x: -50 });
        gsap.set(Board, { y: -25 });

        const tl = gsap.timeline({ paused: true });
        tl
          .to(ManSitting, { autoAlpha: 1, x: 0, duration: .5 })
          .to(Board, { autoAlpha: 1, y: 0, duration: .5 })
          .to(Plant, { autoAlpha: 1, x: 0, duration: .5 })

        ScrollTrigger.create({
            trigger: '.svg_wrapper2',
            start: '15% 75%',
            end: '15% 75%',
            onEnter: () => tl.play(),
            onEnterBack: () => tl.reverse(),
        });
    }, [])

    return (
        <Section paddingBottom className="other_services">
            <Container noPadding>
                <GridContainer reverted>
                    <SvgWrapper revert_order className="svg_wrapper2">
                        <Svg/>
                    </SvgWrapper>
                    <TextWrapper>
                        <SectionHeader dark>
                            {t('what_else.header_line_1')}
                            <span className="underlined">
                                {t('what_else.header_line_2')}
                            </span>{' '}
                            <span role="img" aria-label="emoji"> 💻</span>
                        </SectionHeader>
                        <p className="open_line">{t('what_else.open_line')}</p>
                        <StyledList>
                            {skills.map( skill => (
                              <Li key={skill}>
                                  {t(skill)}
                              </Li>
                            ))}
                        </StyledList>
                    </TextWrapper>
                </GridContainer>
            </Container>
        </Section>
    )
};

export default WhatElseCanIDo;


import React, { useEffect, useRef } from "react"
import styled from 'styled-components';
import { gsap } from 'gsap';

const StyledLi = styled.li`
    color: var(--textTitle);
    margin-bottom: ${({ marginValue }) => marginValue ? `${marginValue}px` : '0'};
    opacity: 0;
    
    &::marker {
        color: var(--primary);
    }
`;

const Span = ({ children, marginValue, opacity0 }) => {

    const LiRef = useRef();

    useEffect(() => {



        const li = gsap.utils.toArray(LiRef.current);
        gsap.set(li, { autoAlpha: 0, duration: .5, y: 25 });
        li.forEach((el, index) => {
            gsap.set(li[index], { duration: .5 })
            gsap.to(li[index], { scrollTrigger: {
                trigger: li[0],
                start: '-10% 95%',
                end: '-10% 95%',
                onEnter: () => gsap.to(li[index], { y: 0, autoAlpha: 1, duration: .5 }),
                onEnterBack: () => gsap.to(li[index], { y: 25, autoAlpha: 0, duration: .5 }),
            }});
        });


        // const startAndEnd = '-30% 85%';

        // gsap.set(LiRef.current, { y : 25, ease: 'power4.out' });
        // ScrollTrigger.batch(".li", {
        //     interval: .1,
        //     duration: 1.75,
        //     onEnter: batch => gsap.to(batch, { autoAlpha: 1, y: 0, stagger: .4}),
        //     onEnterBack: batch => {
        //         console.log('Im entering back!!! 🔥');
        //         gsap.set(batch, { y: 25, autoAlpha: 0, stagger: .4, duration: 5})
        //     },
        //     start: startAndEnd,
        //     end: startAndEnd,
        // });

        // li.forEach((el, index) => {
        //   gsap.set(li[index], { duration: 1, autoAlpha: 0 })
        //   gsap.fromTo(li[index],{ y: 100 }, { y: 0, autoAlpha: 1, scrollTrigger: {
        //       trigger: li[index],
        //       start: '-10% 90%',
        //       end: '-9% 90%',
        //       toggleActions: "restart none none none",
        //       onEnterBack: () => gsap.to(li[index], { y: 100, autoAlpha: 0 }),
        //     }});
        // });
    }, []);

    return (
        <StyledLi ref={LiRef} className="li" margin={marginValue} opacity0={opacity0}>
            {children}
        </StyledLi>
    );
};

export default Span;